import dayjs from "dayjs";
import { createSelector } from "reselect";

import {
  Category,
  ICategorizedResponse,
  IdEnum,
  IIdLodgings,
  ITrackingProperties,
  PremierCollectionEntryProperties,
  StatementCreditDetail,
  StayTypesEnum,
  VacationRentalEntryProperties,
} from "redmond";

import { IStoreState } from "../../../../reducers/types";
import {
  getAgentEmail,
  getIsFirstLaunch,
  getRewardsAccounts,
  getSelectedAccount,
} from "../../../rewards/reducer/selectors";
import { SearchParams } from "../../../shop/utils/queryStringHelpers";
import {
  getCreditBreakdown,
  getTravelWalletCredit,
} from "../../../travel-wallet/reducer";
var levenshtein = require("fast-levenshtein");

export const getLocation = (state: IStoreState) =>
  state.premierCollectionSearch.location;

export const getLocationCategories = (state: IStoreState) =>
  state.premierCollectionSearch.locationCategories;

export const getPCLCCategories = (state: IStoreState) =>
  state.premierCollectionSearch.pclcCategories;

export const getLocationCategoriesLoading = (state: IStoreState) =>
  state.premierCollectionSearch.locationCategoriesLoading;

export const getFromDate = (state: IStoreState) =>
  state.premierCollectionSearch.fromDate;

export const getUntilDate = (state: IStoreState) =>
  state.premierCollectionSearch.untilDate;

export const getAdultsCount = (state: IStoreState) =>
  state.premierCollectionSearch.adultsCount;

export const getChildrenCount = (state: IStoreState) =>
  state.premierCollectionSearch.children.length;

export const getChildren = (state: IStoreState) =>
  state.premierCollectionSearch.children;

export const getPetsCount = (state: IStoreState) =>
  state.premierCollectionSearch.petsCount;

export const getLocationSearchString = (state: IStoreState) =>
  state.premierCollectionSearch.locationSearchString;

export const getMegaMenuRegions = (state: IStoreState) =>
  state.premierCollectionSearch.megaMenuRegions;

export const getStayType = (state: IStoreState) =>
  state.premierCollectionSearch.stayType;

export const getVacationRentalsLocation = (state: IStoreState) =>
  state.premierCollectionSearch.vacationRentalsLocation;

export const getVacationRentalsLocationCategories = (state: IStoreState) =>
  state.premierCollectionSearch.vacationRentalsLocationCategories;

export const getVacationRentalsLocationCategoriesLoading = (
  state: IStoreState
) => state.premierCollectionSearch.vacationRentalsLocationCategoriesLoading;

export const getMegaMenuEnabled = (state: IStoreState) =>
  state.premierCollectionSearch.megaMenuEnabled;

export const getSearchParams = createSelector(
  getStayType,
  getVacationRentalsLocation,
  getLocation,
  getFromDate,
  getUntilDate,
  getAdultsCount,
  getChildrenCount,
  getPetsCount,
  (
    stayType,
    vacationRentalsLocation,
    hotelsLocation,
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    petsCount
  ): SearchParams => {
    switch (stayType) {
      case StayTypesEnum.VacationRentals: {
        return {
          type: StayTypesEnum.VacationRentals,
          location: vacationRentalsLocation,
          fromDate,
          untilDate,
          adultsCount,
          childrenCount,
          petsCount,
        };
      }
      case StayTypesEnum.Hotels: {
        return {
          type: StayTypesEnum.Hotels,
          location: hotelsLocation,
          fromDate,
          untilDate,
          adultsCount,
          childrenCount,
        };
      }
    }
  }
);

//create type called location with label and searchterm
type Location = {
  label: string;
  searchTerm: string;
};

export const getLocationCategoriesFilteredBySearchString = createSelector(
  getLocationCategories,
  getPCLCCategories,
  getMegaMenuEnabled,
  getLocationSearchString,
  getStayType,
  getVacationRentalsLocationCategories,
  (
    locationCategories,
    pclcCategories,
    megaMenuEnabled,
    searchString,
    stayType,
    vacationRentalsLocationCategories
  ) => {
    const categories =
      stayType === StayTypesEnum.Hotels
        ? locationCategories
        : vacationRentalsLocationCategories;
    if (categories) {
      if (megaMenuEnabled && stayType === StayTypesEnum.Hotels) {
        const countryLabels: Location[] = [];
        const stateLabels: Location[] = [];
        const cityLabels: Location[] = [];

        pclcCategories?.forEach((category) => {
          switch (category.label) {
            case "Countries":
              category.results.forEach((result) => {
                countryLabels.push({
                  label: result.label,
                  searchTerm: (result.id as IIdLodgings).lodgingSelection
                    .searchTerm,
                });
              });
              break;
            case "Regions":
              category.results.forEach((result) => {
                stateLabels.push({
                  label: result.label,
                  searchTerm: (result.id as IIdLodgings).lodgingSelection
                    .searchTerm,
                });
              });
              break;
            case "Cities":
              category.results.forEach((result) => {
                cityLabels.push({
                  label: result.label,
                  searchTerm: (result.id as IIdLodgings).lodgingSelection
                    .searchTerm,
                });
              });
              break;
          }
        });

        const categories: ICategorizedResponse[] = [];

        const addCategory = (
          label: string,
          resultsArray: Location[],
          searchString: string,
          category: Category
        ) => {
          categories.push({
            label,
            results: resultsArray
              .filter((item) =>
                item.label.toLowerCase().includes(searchString.toLowerCase())
              )
              .map((item) => ({
                id: {
                  lodgingSelection: {
                    searchTerm: encodeURIComponent(item.searchTerm),
                  },
                  Id: IdEnum.Lodgings,
                },
                label: item.label,
                results: [],
              })),
            category,
          });
        };

        addCategory("Countries", countryLabels, searchString, Category.Places);
        addCategory("States", stateLabels, searchString, Category.Places);
        addCategory("Cities", cityLabels, searchString, Category.Places);

        //sort alphabetically if no search string is present
        if (searchString.length === 0) {
          categories.forEach((category) => {
            category.results.sort((a, b) => {
              return a.label.localeCompare(b.label);
            });
          });
        } else {
          //sort results based on levenstein distance
          categories.forEach((category) => {
            category.results.sort((a, b) => {
              const distanceA = levenshtein.get(a.label, searchString);
              const distanceB = levenshtein.get(b.label, searchString);
              return distanceA - distanceB;
            });
          });

          //sort categories based on levenstein distance of closest result
          categories.sort((a, b) => {
            const distanceA =
              a.results.length > 0
                ? levenshtein.get(a.results[0].label, searchString)
                : 0;
            const distanceB =
              b.results.length > 0
                ? levenshtein.get(b.results[0].label, searchString)
                : 0;
            return distanceA - distanceB;
          });
        }

        return categories;
      } else {
        return categories.map((locationCategory) => ({
          ...locationCategory,
          results: locationCategory.results.filter((result) =>
            result.label.toLowerCase().includes(searchString.toLowerCase())
          ),
        }));
      }
    }

    return [];
  }
);

export const getNightCount = createSelector(
  getFromDate,
  getUntilDate,
  (fromDate, untilDate): number | null => {
    if (fromDate && untilDate) {
      return dayjs(untilDate).diff(dayjs(fromDate), "day");
    } else {
      return null;
    }
  }
);

export const getPaymentMethods = (state: IStoreState) =>
  state.premierCollectionAvailability.paymentMethods;

export const getPremierCollectionEntryProperties = createSelector(
  getAgentEmail,
  getLocation,
  getFromDate,
  getIsFirstLaunch,
  getRewardsAccounts,
  getAdultsCount,
  getChildrenCount,
  getTravelWalletCredit,
  getCreditBreakdown,
  getSelectedAccount,
  getPaymentMethods,
  (
    agentEmail,
    location,
    fromDate,
    isFirstLaunch,
    rewardsAccounts,
    numAdults,
    numChildren,
    credit,
    creditBreakdown,
    selectedAccount,
    paymentMethods,
  ): ITrackingProperties<PremierCollectionEntryProperties> => {
    return {
      properties: {
        delegated_to: agentEmail || "",
        first_launch: isFirstLaunch,
        market: location?.label || "",
        // this is the best effort way to parse the country out of the location label
        // this value will not be correct all of the time since address are known to be difficult to parse
        // it should only be used for tracking purposes and not relied on for anything else
        country:
          location?.subLabel || location?.label?.split(", ")?.pop() || "",
        number_of_properties: 0,
        number_of_guests: numAdults + numChildren,
        hotel_advance: fromDate
          ? `${dayjs(fromDate).diff(dayjs(), "day")} days`
          : "",
        rooms_searched: 1, // TODO: Update when multiroom is added for lhc
        rewards_accounts: rewardsAccounts
          .map((r) => r.productDisplayName)
          .join(","),
        ...location?.trackingPropertiesV2?.properties,
        has_credits: !!credit?.amount?.amount,
        credit_balance: !!credit?.amount?.amount
          ? Math.abs(credit.amount.amount)
          : 0,
        vx_statement_credit_balance:
          creditBreakdown
            ?.filter((b) => b.CreditDetail === "Statement")
            .reduce(
              (prev, curr) =>
                prev + (curr as StatementCreditDetail).usableAmount.amount,
              0
            ) || 0,
        customer_account_role: selectedAccount?.customerAccountRole,
        card_on_file: paymentMethods.length > 0,
      },
      encryptedProperties: [
        location?.trackingPropertiesV2?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getHasLocationAutocompleteError = (state: IStoreState) =>
  state.premierCollectionSearch.hasLocationAutocompleteError;

export const getVacationRentalEntryProperties = createSelector(
  getAgentEmail,
  getVacationRentalsLocation,
  getFromDate,
  getIsFirstLaunch,
  getUntilDate,
  getAdultsCount,
  getChildrenCount,
  getTravelWalletCredit,
  getCreditBreakdown,
  getSelectedAccount,
  getPaymentMethods,
  (
    agentEmail,
    location,
    fromDate,
    isFirstLaunch,
    untilDate,
    numAdults,
    numChildren,
    credit,
    creditBreakdown,
    selectedAccount,
    paymentMethods
  ): ITrackingProperties<VacationRentalEntryProperties> => {
    return {
      properties: {
        delegated_to: agentEmail || "",
        first_launch: isFirstLaunch,
        has_credits: !!credit?.amount?.amount,
        credit_balance: !!credit?.amount?.amount
          ? Math.abs(credit.amount.amount)
          : 0,
        vx_statement_credit_balance:
          creditBreakdown
            ?.filter((b) => b.CreditDetail === "Statement")
            .reduce(
              (prev, curr) =>
                prev + (curr as StatementCreditDetail).usableAmount.amount,
              0
            ) || 0,
        customer_account_role: selectedAccount?.customerAccountRole,
        adults_count: numAdults,
        advance: fromDate ? dayjs(fromDate).diff(dayjs(), "day") : undefined,
        check_in_date: dayjs(fromDate).format("YYYY-MM-DD"),
        check_out_date: dayjs(untilDate).format("YYYY-MM-DD"),
        children_count: numChildren,
        destination_city: location?.label.split(",")?.length
          ? location?.label.split(",")?.length > 1
            ? location?.label.split(",")[0]
            : location?.label
          : "",
        destination_country:
          location?.subLabel || location?.label?.split(", ")?.pop() || "",
        los:
          fromDate && untilDate
            ? dayjs(untilDate).diff(fromDate, "day")
            : undefined,
        card_on_file: paymentMethods.length > 0,
      },
      encryptedProperties: [
        location?.trackingPropertiesV2?.encryptedProperties ?? "",
      ],
    };
  }
);
