import {
  GuestsSelection,
  ICategorizedResponse,
  IResult,
  MegaMenuRegion,
  NonEmptyArray,
  StayTypesEnum,
} from "redmond";

import * as actionTypes from "./constants";

export interface IFetchLocationCategories {
  type: actionTypes.FETCH_LOCATION_CATEGORIES;
}

export const fetchLocationCategories = (): IFetchLocationCategories => ({
  type: actionTypes.FETCH_LOCATION_CATEGORIES,
});

export interface IFetchLifestyleLocationCategories {
  type: actionTypes.FETCH_LIFESTYLE_LOCATION_CATEGORIES;
}

export const fetchLifestyleLocationCategories =
  (): IFetchLifestyleLocationCategories => ({
    type: actionTypes.FETCH_LIFESTYLE_LOCATION_CATEGORIES,
  });

export interface ISetLocationCategories {
  type: actionTypes.SET_LOCATION_CATEGORIES;
  categories: ICategorizedResponse[];
}

export const setLocationCategories = (
  categories: ICategorizedResponse[]
): ISetLocationCategories => ({
  type: actionTypes.SET_LOCATION_CATEGORIES,
  categories,
});

export interface IFetchPCLCLocationCategories {
  type: actionTypes.FETCH_PCLC_LOCATION_CATEGORIES;
}

export const fetchPCLCLocationCategories =
  (): IFetchPCLCLocationCategories => ({
    type: actionTypes.FETCH_PCLC_LOCATION_CATEGORIES,
  });

export interface ISetPCLCLocationCategories {
  type: actionTypes.SET_PCLC_LOCATION_CATEGORIES;
  pclcCategories: ICategorizedResponse[];
}

export const setPCLCLocationCategories = (
  pclcCategories: ICategorizedResponse[]
): ISetPCLCLocationCategories => ({
  type: actionTypes.SET_PCLC_LOCATION_CATEGORIES,
  pclcCategories,
});

export interface IFetchMegaMenuRegions {
  type: actionTypes.FETCH_MEGAMENU_REGIONS;
}

export const fetchMegaMenuRegions = (): IFetchMegaMenuRegions => ({
  type: actionTypes.FETCH_MEGAMENU_REGIONS,
});

export interface ISetMegaMenuRegions {
  type: actionTypes.SET_MEGAMENU_REGIONS;
  regions: NonEmptyArray<MegaMenuRegion>;
}

export const setMegaMenuRegions = (
  regions: NonEmptyArray<MegaMenuRegion>
): ISetMegaMenuRegions => ({
  type: actionTypes.SET_MEGAMENU_REGIONS,
  regions,
});

export interface IFetchMegaMenuEnabled {
  type: actionTypes.FETCH_MEGAMENU_ENABLED;
}

export const fetchMegaMenuEnabled = (): IFetchMegaMenuEnabled => ({
  type: actionTypes.FETCH_MEGAMENU_ENABLED,
});

export interface ISetMegaMenuEnabled {
  type: actionTypes.SET_MEGAMENU_ENABLED;
  enabled: boolean;
}

export const setMegaMenuEnabled = (enabled: boolean): ISetMegaMenuEnabled => ({
  type: actionTypes.SET_MEGAMENU_ENABLED,
  enabled,
});

export interface ISetLocation {
  type: actionTypes.SET_LOCATION;
  location: IResult | null;
}

export const setLocation = (location: IResult | null): ISetLocation => ({
  type: actionTypes.SET_LOCATION,
  location,
});

export interface ISetFromDate {
  type: actionTypes.SET_FROM_DATE;
  date: Date | null;
}

export const setFromDate = (date: Date | null): ISetFromDate => ({
  type: actionTypes.SET_FROM_DATE,
  date,
});

export interface ISetUntilDate {
  type: actionTypes.SET_UNTIL_DATE;
  date: Date | null;
}

export const setUntilDate = (date: Date | null): ISetUntilDate => ({
  type: actionTypes.SET_UNTIL_DATE,
  date,
});

export interface ISetOccupancyCounts {
  type: actionTypes.SET_OCCUPANCY_COUNTS;
  counts: Omit<GuestsSelection, "rooms">;
}

export const setOccupancyCounts = (
  counts: Omit<GuestsSelection, "rooms">
): ISetOccupancyCounts => ({
  type: actionTypes.SET_OCCUPANCY_COUNTS,
  counts,
});

export interface ISetLocationAutocompleteError {
  type: actionTypes.SET_LOCATION_AUTOCOMPLETE_ERROR;
  error: boolean;
}

export const setLocationAutocompleteError = (
  error: boolean
): ISetLocationAutocompleteError => ({
  type: actionTypes.SET_LOCATION_AUTOCOMPLETE_ERROR,
  error,
});

export interface ISetLocationSearchString {
  type: actionTypes.SET_LOCATION_SEARCH_STRING;
  searchString: string;
}

export const setLocationSearchString = (
  searchString: string
): ISetLocationSearchString => ({
  type: actionTypes.SET_LOCATION_SEARCH_STRING,
  searchString,
});

export interface ISetStayType {
  type: actionTypes.SET_STAY_TYPE;
  stayType: StayTypesEnum;
}

export const setStayType = (stayType: StayTypesEnum): ISetStayType => ({
  type: actionTypes.SET_STAY_TYPE,
  stayType,
});

export interface IFetchVacationRentalsLocationCategories {
  type: actionTypes.FETCH_VACATION_RENTALS_LOCATION_CATEGORIES;
}

export const fetchVacationRentalsLocationCategories =
  (): IFetchVacationRentalsLocationCategories => ({
    type: actionTypes.FETCH_VACATION_RENTALS_LOCATION_CATEGORIES,
  });

export interface ISetVacationRentalsLocationCategories {
  type: actionTypes.SET_VACATION_RENTALS_LOCATION_CATEGORIES;
  vacationRentalsCategories: ICategorizedResponse[];
}

export const setVacationRentalsLocationCategories = (
  vacationRentalsCategories: ICategorizedResponse[]
): ISetVacationRentalsLocationCategories => ({
  type: actionTypes.SET_VACATION_RENTALS_LOCATION_CATEGORIES,
  vacationRentalsCategories,
});

export interface ISetVacationRentalsLocation {
  type: actionTypes.SET_VACATION_RENTALS_LOCATION;
  vacationRentalsLocation: IResult | null;
}

export const setVacationRentalsLocation = (
  vacationRentalsLocation: IResult | null
): ISetVacationRentalsLocation => ({
  type: actionTypes.SET_VACATION_RENTALS_LOCATION,
  vacationRentalsLocation,
});

export type HotelSearchActions =
  | IFetchLocationCategories
  | ISetLocationCategories
  | IFetchPCLCLocationCategories
  | ISetPCLCLocationCategories
  | IFetchMegaMenuRegions
  | ISetMegaMenuRegions
  | IFetchMegaMenuEnabled
  | ISetMegaMenuEnabled
  | ISetLocation
  | ISetFromDate
  | ISetUntilDate
  | ISetOccupancyCounts
  | ISetLocationAutocompleteError
  | ISetLocationSearchString
  | ISetStayType
  | IFetchVacationRentalsLocationCategories
  | ISetVacationRentalsLocationCategories
  | ISetVacationRentalsLocation;
