import { all } from "redux-saga/effects";
import { sagas as premierCollectionSearch } from "../modules/search";
import { sagas as premierCollectionRewards } from "../modules/rewards";
import { sagas as premierCollectionAvailability } from "../modules/availability";
import { sagas as premierCollectionShop } from "../modules/shop";
import { sagas as premierCollectionBook } from "../modules/book";
import { sagas as premierCollectionTravelWallet } from "../modules/travel-wallet";
// TODO: refactor it as a package, see https://hopper-jira.atlassian.net/browse/BF-1514
import { sagas as hotelAncillary } from "../modules/ancillary";
import { sagas as vacationRentalShop } from "../modules/vacation-rental-shop";

export function* rootSaga() {
  yield all([
    premierCollectionSearch.watchFetchLocationCategories(),
    premierCollectionSearch.watchFetchLifestyleLocationCategories(),
    premierCollectionSearch.watchFetchVacationRentalsLocationCategories(),
    premierCollectionSearch.watchMegaMenuRegions(),
    premierCollectionSearch.watchFetchPCLCLocationCategories(),
    premierCollectionRewards.watchFetchRewardsAccounts(),

    premierCollectionAvailability.watchFetchPremierCollectionAvailability(),
    premierCollectionAvailability.watchFetchVacationRentalsAvailability(),
    premierCollectionAvailability.watchListPCAvailabilityPaymentMethods(),

    premierCollectionShop.watchFetchPremierCollectionShopSaga(),

    premierCollectionBook.watchFetchUserPassengers(),
    premierCollectionBook.watchDeletePassengerSaga(),
    premierCollectionBook.watchUpdateUserPassenger(),
    premierCollectionBook.watchFetchCorpUserPassengers(),

    premierCollectionBook.watchFetchPaymentMethodSaga(),
    premierCollectionBook.watchDeletePaymentMethodSaga(),
    premierCollectionBook.watchListPaymentMethodsSaga(),
    premierCollectionBook.watchVerifyPaymentMethodSaga(),

    premierCollectionBook.watchProductToEarnSaga(),

    premierCollectionBook.watchSchedulePriceQuoteSaga(),
    premierCollectionBook.watchPollPriceQuoteSaga(),
    premierCollectionBook.watchscheduleBookSaga(),
    premierCollectionBook.watchPollConfirmationDetailsSaga(),
    premierCollectionBook.watchFetchApplicableTravelWalletItemsSaga(),

    premierCollectionBook.watchScheduleVacationRentalPriceQuoteSaga(),
    premierCollectionBook.watchPollVacationRentalsPriceQuoteSaga(),
    premierCollectionBook.watchAddMainGuestSaga(),
    premierCollectionBook.watchScheduleVacationRentalsBookSaga(),
    premierCollectionBook.watchPollVacationRentalsConfirmationDetailsSaga(),
    premierCollectionBook.watchSubmitForApprovalSaga(),

    premierCollectionTravelWallet.watchFetchTravelWalletDetails(),
    premierCollectionTravelWallet.watchFetchTravelWalletCreditHistorySaga(),

    hotelAncillary.watchFetchCfarOffers(),

    vacationRentalShop.watchFetchVacationRentalShopSaga(),
  ]);
}
